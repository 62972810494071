<template>
  <v-row>
    <v-col>
      <div class="tw-ml-2">
        <v-radio-group
          v-model="formData.mediaPermissions"
          row
        >
          <v-radio
            label="Audio only"
            value="audio"
          />
          <v-radio
            label="Audio and video"
            value="audio-video"
          />
        </v-radio-group>

        <v-checkbox
          v-model="formData.shareScreen"
          label="Screen-sharing"
        />

        <v-checkbox
          v-model="formData.setNickname"
          label="Set nickname"
        />
      </div>
    </v-col>
    <v-divider vertical />
    <v-col>
      <br>
      <template v-if="!computedPermissions.permissions.length === 0">
        <p>
          No permissions have been selected.
        </p>
      </template>
      <template v-else>
        <div
          v-for="(node, i) in computedPermissions.human"
          :key="i"
        >
          <p v-text="node.desc" />
        </div>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { find } from 'lodash';
import Permissions from '../../assets/permissions/en/presenter.json';

export default {
  props: {
    defaultData: {
      type: Object,
      required: false,
      default: () => ({
        selected: ['CAN_SEND_AUDIO'],
        mediaPermissions: 'audio-video',
        shareScreen: true,
        setNickname: true,
      }),
    },
  },

  data: () => ({
    items: Permissions,

    formData: {},
  }),

  computed: {
    computedPermissions() {
      const permissions = [];
      permissions.push(find(Permissions, ['label', this.formData.mediaPermissions]));

      if (this.formData.shareScreen) permissions.push(find(Permissions, ['label', 'share-screen']));
      if (this.formData.setNickname) permissions.push(find(Permissions, ['label', 'set-nickname']));

      return {
        human: permissions,
        permissions: permissions.map((val) => (val ? val.value : null)).flat(),
      };
    },
  },

  watch: {
    defaultData: {
      deep: true,
      immediate: true,
      handler() {
        this.formData = { ...this.defaultData };
      },
    },
  },

  methods: {
    getData() {
      return { permissionsPresenter: this.computedPermissions.permissions };
    },
  },
};
</script>
