const pagination = {
  data: () => ({
    pagination: {
      skip: 0,
      take: -1,
      sortBy: null,
      sortOrder: 'DESC',
    },
    customParams: {},
  }),

  computed: {
    computedParams() {
      return {
        ...this.pagination,
        ...this.customParams,
      };
    },
  },

  methods: {
    updatePagination(event) {
      const skip = (event.page - 1) * this.pagination.take;
      this.pagination.skip = skip >= 0 ? skip : 0;
    },

    updateTake(take) {
      this.pagination.take = take;
    },

    updateSort(data) {
      this.pagination = {
        ...this.pagination,
        sortBy: data.sortBy[0],
        sortOrder: (!data.sortDesc.length || data.sortDesc[0]) ? 'DESC' : 'ASC',
      };
    },
  },
};

export default pagination;
