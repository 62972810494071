<template>
  <component :is="$route.meta.layout">
    <v-stepper
      v-model="step"
      :alt-labels="true"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Room settings
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Room videos
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Host permissions
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 4"
          step="4"
        >
          Attendee permissions
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 5"
          step="5"
        >
          Join links
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <CreateRoom />
        </v-stepper-content>

        <v-stepper-content step="2">
          <AddVideos />
        </v-stepper-content>

        <v-stepper-content step="3">
          <AddPresenters />
        </v-stepper-content>

        <v-stepper-content step="4">
          <AddAttendees />
        </v-stepper-content>

        <v-stepper-content step="5">
          <RoomLinks />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <ConfirmDialog
      :active="modal"
    >
      <template v-slot:title>
        Are you sure you want to quit the process?
      </template>

      <template v-slot:text>
        <p>
          Leaving without completing the process will result in loss of all data for this room.
        </p>
        <p class="tw-text-red-500 tw-font-bold">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="remain"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="cancelCreation"
        >
          Confirm
        </v-btn>
      </template>
    </ConfirmDialog>
  </component>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ConfirmDialog from '../../components/ConfirmDialog';
import WizardComponents from '../../components/wizard/room/room-wizard';

export default {
  components: {
    ...WizardComponents,
    ConfirmDialog,
  },

  data: () => ({
    presenters: [],
    modal: false,
    to: null,
  }),

  computed: {
    ...mapState('wizard', ['step', 'roomId']),
  },

  created() {
    this.resetState();
  },

  async beforeRouteLeave(to, from, next) {
    if (this.step === 5) next();

    if (!this.to) {
      this.to = to;
      this.modal = true;
    } else {
      if (this.roomId) await this.deleteRoom(this.roomId);
      next();
    }
  },

  methods: {
    ...mapMutations('wizard', ['resetState', 'nextStep', 'prevStep']),

    ...mapActions('rooms', ['deleteRoom']),

    cancelCreation() {
      this.$router.push({ name: 'RoomIndex' });
    },

    remain() {
      this.to = null;
      this.modal = false;
    },
  },
};
</script>

<style>
  .v-application--is-ltr .v-stepper__label {
    text-align: center !important;
    line-height: 1.2rem;
  }
</style>
