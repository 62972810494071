<template>
  <component :is="$route.meta.layout">
    <v-card v-if="canBeUpdated">
      <v-card-title primary-title>
        Update room settings
      </v-card-title>
      <v-card-subtitle>
        Room settings and permissions can be updated when the room is not in use.
      </v-card-subtitle>

      <RoomForm
        :disabled="typeof error === 'string'"
        :room-data="roomData"
        :allow-duplication="true"
        @form-cancel="cancelUpdate"
        @form-submit="submitRoom"
        @form-duplicate="attemptDuplicate"
        @form-input="updateData"
      >
        <template v-slot:videoManagement>
          <h4 class="v-card__title tw-px-0">
            Videos in the Room
          </h4>
          <p class="v-card__subtitle tw-px-0 tw-pb-0">
            Here, you can decide which videos are available in the room.
          </p>

          <p
            v-if="error"
            class="v-card__subtitle tw-px-0 tw-red-500"
            v-text="error"
          />

          <room-videos
            ref="roomVideos"
            :default-data="videoDefaults"
            @form-error="setError"
          />
        </template>

        <template v-slot:presenterPermissions>
          <h4 class="v-card__title tw-px-0">
            Hosts permissions
          </h4>
          <div class="v-card__subtitle tw-px-0">
            Modify the permissions granted to Hosts in this room.
          </div>

          <v-row>
            <v-col>
              <PresenterPermissions
                ref="presenterPermissions"
                :default-data="presenterDefaults"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:attendeePermissions>
          <h4 class="v-card__title tw-px-0">
            Attendee permissions
          </h4>
          <div class="v-card__subtitle tw-px-0">
            Modify the permissions granted to attendees in this room.
          </div>

          <v-row>
            <v-col>
              <AttendeePermissions
                ref="attendeePermissions"
                :show-desc="false"
                :default-data="attendeesDefaults"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:cancelButtonText>
          Cancel
        </template>

        <template v-slot:confirmButtonText>
          Update room
        </template>

        <template v-slot:duplicateButtonText>
          Duplicate room
        </template>
      </RoomForm>
    </v-card>

    <v-card v-else>
      <v-card-title>
        The room is currently in use.
      </v-card-title>

      <v-card-subtitle>
        You can update the rooms' settings and permissions once the ongoing conference ends.
      </v-card-subtitle>

      <v-card-subtitle>
        <v-btn
          color="info"
          @click="closeRoom"
        >
          Close room
        </v-btn>
      </v-card-subtitle>
    </v-card>

    <br>

    <ParticipantTable
      :presenters="users"
      :enlistment-token="roomData.enlistmentToken"
      @presenters-updated="updatePresenters"
    >
      <template v-slot:finder>
        <PresenterLookup
          ref="lookup"
          :room-id="roomId"
          :presenter-permissions="roomData.permissionsPresenter"
          @presenters-updated="updatePresenters"
        >
          <template v-slot:title>
            Add Hosts
          </template>

          <template v-slot:addPresenterSubtitle>
            The Hosts you create here will be added to this room.
          </template>
        </PresenterLookup>
      </template>
    </ParticipantTable>

    <ConfirmDialog :active="dialog">
      <template v-slot:title>
        Are you sure?
      </template>

      <template v-slot:text>
        <p>
          This action will remove
          "<span
            class="tw-font-bold"
            v-text="selected.nickname"
          />" as a Host from this room.
        </p>
        <p class="tw-text-red-500">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="selectItem()"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="removePresenter(selected.participationId)"
        >
          Confirm
        </v-btn>
      </template>
    </ConfirmDialog>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { omit, isEmpty } from 'lodash';
import confirms from '../../helpers/confirms';
import RoomForm from '../../components/forms/RoomForm';
import RoomVideos from '../../components/forms/RoomVideos';
import ConfirmDialog from '../../components/ConfirmDialog';
import ParticipantTable from '../../components/ParticipantTable';
import PresenterLookup from '../../components/forms/PresenterLookup';
import AttendeePermissions from '../../components/forms/AttendeePermissions';
import PresenterPermissions from '../../components/forms/PresenterPermissions';

const removedKeys = ['createdAt', 'updatedAt', 'enlistmentLink', 'start', 'scope'];

export default {
  components: {
    RoomForm,
    RoomVideos,
    ConfirmDialog,
    PresenterLookup,
    ParticipantTable,
    AttendeePermissions,
    PresenterPermissions,
  },

  filters: {
    capitalize: (value) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
  },

  mixins: [confirms],

  props: {
    roomId: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    error: false,
    alert: false,
    roomData: {},
    users: [],
    userCount: null,
    enlistmentToken: '',
    status: 'IN_PROGRESS',

    headers: [
      { text: 'Name', sortable: true, value: 'nickname' },
      { text: 'Role', sortable: true, value: 'role' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
  }),

  computed: {
    presenterIds() {
      if (!this.users.length) return [];
      return this.users.map((presenter) => presenter.user.userId);
    },

    canBeUpdated() {
      return !['IN_PROGRESS', 'LOCKED'].includes(this.status);
    },

    videoDefaults() {
      if (isEmpty(this.roomData)) return null;
      const { settings, videos } = this.roomData;

      return {
        videos,
        videosActive: settings.videosActive,
      };
    },

    attendeesDefaults() {
      if (isEmpty(this.roomData)) return null;
      const { baseName, permissionsAttendee, grantPermissionsOnEnlist } = this.roomData;

      return {
        baseName,
        grantPermissionsOnEnlist,
        setNickname: permissionsAttendee.includes('CAN_SET_NICKNAME'),
        shareScreen: permissionsAttendee.includes('CAN_SHARE_SCREEN'),
        mediaPermissions: permissionsAttendee.includes('CAN_SEND_VIDEO') ? 'audio-video'
          : permissionsAttendee.includes('CAN_SEND_AUDIO') ? 'audio' : 'none',
      };
    },

    presenterDefaults() {
      if (isEmpty(this.roomData)) return false;
      const { permissionsPresenter } = this.roomData;

      return {
        shareScreen: permissionsPresenter.includes('CAN_SHARE_SCREEN'),
        setNickname: permissionsPresenter.includes('CAN_SET_NICKNAME'),
        mediaPermissions: !permissionsPresenter.includes('CAN_SEND_VIDEO') ? 'audio' : 'audio-video',
      };
    },
  },

  watch: {
    roomId: {
      immediate: true,
      handler() {
        this.initialize();
      },
    },
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapMutations(['toggleLoading']),

    ...mapActions('participations', [
      'fetchParticipations',
      'deleteParticipation',
      'createParticipation',
      'updateParticipation',
    ]),

    ...mapActions('rooms', [
      'fetchRoom',
      'createRoom',
      'updateRoom',
      'duplicateRoom',
    ]),

    async initialize() {
      try {
        const {
          roomId, status, enlistmentToken, createdBy, lastUpdatedBy, ...room
        } = await this.fetchRoom(this.roomId);

        this.updatePresenters();

        this.status = status;
        this.createdBy = createdBy;
        this.lastUpdatedBy = lastUpdatedBy;
        this.enlistmentToken = enlistmentToken;

        this.roomData = {
          ...omit(room, removedKeys),
          enlistmentToken,
          id: roomId,
        };
      } catch (error) {
        console.log(error);
        this.setAlert({ message: 'There was an error fetching the room. Please try again later.' });
      }
    },

    async submitRoom(updatedRoom) {
      try {
        const promises = this.users.map(
          (user) => this.updateParticipation({
            role: 'PRESENTER',
            roomId: user.roomId,
            nickname: user.nickname,
            participationId: user.participationId,
            permissions: this.roomData.permissionsPresenter,
          }),
        );

        const { settings, ...room } = updatedRoom;
        const { videosActive, videos } = this.$refs.roomVideos.getData();

        await this.updateRoom({
          id: this.roomId,
          ...room,
          ...this.$refs.attendeePermissions.getData(),
          ...this.$refs.presenterPermissions.getData(),
          videos,
          settings: {
            ...settings,
            videosActive,
            nicknameIsMandatory: true,
          },
        });

        await Promise.all(promises);
        this.$router.push({ name: 'RoomIndex' });
      } catch (error) {
        this.setAlert({
          message: error.response.data.message
            || 'There was an error updating the room. Please try again later.',
        });
      }
    },

    async attemptDuplicate() {
      const duplicate = await this.duplicateRoom(this.roomId);
      this.$router.push({ name: 'RoomUpdate', params: { roomId: duplicate.roomId } });
    },

    cancelUpdate() {
      this.$router.push({ name: 'RoomIndex' });
    },

    navigateToUpdate(presenterId) {
      this.$router.push({ name: 'PresenterUpdate', params: { presenterId } });
    },

    copyToClipboard(joinToken) {
      this.alert = { message: 'Host join URL copied to clipboard!' };
      navigator.clipboard.writeText(`${process.env.VUE_APP_WEBINAR_BASE_URL}?token=${joinToken}`);
      setTimeout(() => {
        this.alert = false;
      }, 2500);
    },

    copyEnlistmentLink() {
      this.alert = { message: 'General enlistment URL copied to clipboard!' };
      navigator.clipboard.writeText(`${process.env.VUE_APP_WEBINAR_BASE_URL}?token=${this.enlistmentToken}`);
      setTimeout(() => {
        this.alert = false;
      }, 2500);
    },

    removePresenter(participationId) {
      this.selectItem();
      this.deleteParticipation({
        participationId,
        loading: false,
      }).then((res) => {
        this.$refs.lookup.spliceSelected(res.participationId);
        this.updatePresenters();
      });
    },

    async updatePresenters() {
      try {
        this.users = (await this.fetchParticipations({
          params: {
            roomId: this.roomId,
            role: 'PRESENTER',
            fetchUser: true,
          },
        })).data;
      } catch (error) {
        // TODO: error catching
      }
    },

    updateData(newData) {
      this.roomData = { ...this.roomData, ...newData };
    },

    async closeRoom() {
      await this.updateRoom({
        id: this.roomId,
        status: 'AVAILABLE',
      });

      this.initialize();
    },

    setError(e) {
      this.error = typeof e === 'string'
        ? e : false;
    },
  },
};
</script>
