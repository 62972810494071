<template>
  <div>
    <PresenterLookup
      ref="lookup"
      :presenter-ids="presenterIds"
      :presenter-permissions="perms"
      :room-id="roomId"
    />

    <PresenterPermissions ref="permissionsPresenter" />

    <v-btn
      color="warning"
      class="mr-4"
      @click="prevStep"
    >
      <slot name="confirmButtonText">
        Previous step
      </slot>
    </v-btn>

    <v-btn
      :disabled="!presenters.length"
      color="success"
      class="mr-4"
      @click="validate"
    >
      <slot name="confirmButtonText">
        Continue
      </slot>
    </v-btn>

    <v-dialog
      v-model="modal"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-card-title>
            <span class="headline">Please wait...</span>
          </v-card-title>

          <v-card-subtitle>
            This can take a few seconds...
          </v-card-subtitle>

          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PresenterLookup from '../../forms/PresenterLookup';
import PresenterPermissions from '../../forms/PresenterPermissions';

export default {
  components: {
    PresenterLookup,
    PresenterPermissions,
  },

  data: () => ({
    perms: [],
    modal: false,
  }),

  computed: {
    ...mapState('wizard', ['roomId', 'presenters']),

    presenterIds() {
      if (!this.presenters.length) return [];
      return this.presenters.map((item) => item.userId);
    },
  },

  methods: {
    ...mapActions('rooms', ['updateRoom']),
    ...mapActions('participations', ['updateParticipation']),

    ...mapMutations('AlertModule', ['setAlert']),
    ...mapMutations('wizard', [
      'nextStep',
      'prevStep',
      'setPresenters',
    ]),

    async validate() {
      if (this.presenters.length) {
        try {
          const { permissionsPresenter } = this.$refs.permissionsPresenter.getData();

          await Promise.all(this.presenters.map(
            (presenter) => this.updateParticipation({
              roomId: this.roomId,
              permissions: permissionsPresenter,
              participationId: presenter.participationId,
            }),
          ));

          await this.updateRoom({
            id: this.roomId,
            permissionsPresenter: [...permissionsPresenter],
          });

          this.nextStep();
        } catch (error) {
          this.setAlert({ message: 'There was an error updating the room. Please try again later.' });
        }
      }
    },
  },
};
</script>

<style>
.mb-0 {
  margin-bottom: 0;
}
</style>
