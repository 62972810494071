<template>
  <div>
    <ParticipantTable
      :presenters="presenters"
      :enlistment-token="enlistmentToken"
      @presenters-updated="updateParticipations"
    />

    <br>

    <v-row>
      <v-col>
        <v-btn
          color="warning"
          class="mr-4"
          @click="prevStep"
        >
          <slot name="confirmButtonText">
            Previous step
          </slot>
        </v-btn>

        <v-btn
          color="success"
          class="mr-4"
          @click="exit"
        >
          <slot name="confirmButtonText">
            Save and Exit
          </slot>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ParticipantTable from '../../ParticipantTable';

export default {
  components: {
    ParticipantTable,
  },

  computed: {
    ...mapState('wizard', ['roomId', 'presenters', 'enlistmentToken']),
  },

  methods: {
    ...mapMutations('wizard', ['prevStep']),
    ...mapActions('wizard', ['updateParticipations']),

    exit() {
      this.$router.push({ name: 'RoomIndex' });
    },
  },
};
</script>
