<template>
  <div class="tw-p-4">
    <h4 class="v-card__title tw-px-0">
      Videos in the Room
    </h4>
    <p class="v-card__subtitle tw-px-0">
      Here, you can decide which videos are available in the room.
    </p>

    <p
      v-if="error"
      class="v-card__subtitle tw-px-0 tw-red-500"
      v-text="error"
    />

    <RoomVideos
      ref="videos"
      @form-error="setError"
    />

    <div class="pt-8">
      <v-btn
        color="warning"
        class="mr-4"
        @click="prevStep"
      >
        <slot name="confirmButtonText">
          Previous step
        </slot>
      </v-btn>

      <v-btn
        color="success"
        class="mr-4"
        :disabled="typeof error === 'string'"
        @click="validate"
      >
        <slot name="confirmButtonText">
          Continue
        </slot>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import RoomVideos from '../../forms/RoomVideos';

export default {
  components: {
    RoomVideos,
  },

  data: () => ({
    error: false,
  }),

  computed: {
    ...mapState('wizard', ['room']),
  },

  methods: {
    ...mapActions('rooms', ['updateRoom']),
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapMutations('wizard', [
      'nextStep',
      'prevStep',
    ]),

    validate() {
      const { roomId, settings } = this.room;

      try {
        const { videos, videosActive } = this.$refs.videos.getData();
        this.updateRoom({
          id: roomId,
          videos,
          settings: {
            ...settings,
            videosActive,
          },
        })
          .then(() => this.nextStep())
          .catch(() => this.setAlert({
            message: 'There was an error adding the room videos. Please try again later.',
          }));
      } catch (error) {
        return false;
      }
      return true;
    },

    setError(e) {
      this.error = typeof e === 'string'
        ? e : false;
    },
  },
};
</script>
