<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="tw-p-4"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="formData.name"
          :counter="150"
          :rules="formValidation.name"
          label="Room name"
          persistent-hint
          hint="Human readable webinar room name, use letters and numbers only, max 150 characters."
          required
        />
      </v-col>
    </v-row>

    <v-row v-if="accountType === 'SUPER'">
      <v-col>
        <v-switch
          v-model="formData.isDefault"
          label="Set this Room as the Default configuration for this Scope."
          hint="There can only be one 'default' Room per Scope."
          persistent-hint
          inset
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="formData.settings.showChatModule"
          label="Show chat module."
          persistent-hint
          hint="If selected, the public chat will be enabled."
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="formData.settings.showParticipationList"
          label="Show participant list."
          persistent-hint
          hint="If selected, a listing of participant will be displayed to all attendees."
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="formData.settings.showPollingModule"
          label="Show polling module."
          persistent-hint
          hint="If selected, the polling tab will be displayed to all attendees."
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-checkbox
          v-model="formData.settings.clearChat"
          label="Clear chat history when the room closes."
          persistent-hint
          hint="If selected, the chat history will be deleted every time the room closes."
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-checkbox
          v-model="formData.settings.raiseHandAllowed"
          label="Allows participants to raise hand."
          persistent-hint
          hint="If selected, the raise hand feature will be enabled for this conference."
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-checkbox
          v-model="formData.settings.canLock"
          label="Room locking."
          persistent-hint
          hint="
             If you enable room locking, you can define the default room state and
             Hosts will be able to lock/unlock rooms at runtime during the meeting.
          "
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-label>
          <div>Room default state.</div>
        </v-label>

        <v-radio-group
          v-model="formData.settings.defaultLaunchStatus"
          :disabled="!formData.settings.canLock"
          row
          persistent-hint
          hint="
            When the room is unlocked it is open for all attendees to join in.
            When the room is locked, no new attendees can join in. Hosts can join anytime.
          "
        >
          <v-radio
            label="Unlocked"
            value="IN_PROGRESS"
          />
          <v-radio
            label="Locked"
            value="LOCKED"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="formData.pollUrl"
          :rules="[pollUrlValid]"
          label="Poll URL"
          persistent-hint
          hint="Poll to show when the poll module is activated. (optional)"
          required
        />
      </v-col>
      <v-col
        md="6"
        sm="12"
      >
        <v-text-field
          v-model="formData.presenterControlUrl"
          :rules="[controlUrlValid]"
          label="Poll control URL"
          persistent-hint
          hint="Control panel for the Host to control the poll. (optional)"
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        sm="12"
      >
        <v-label>
          <div>Reply in chat</div>
        </v-label>

        <v-radio-group
          v-model="formData.settings.allowChatReplies"
          row
        >
          <v-radio
            label="Hosts only"
            :value="false"
          />
          <v-radio
            label="Hosts and Attendees"
            :value="true"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-label>
          <div>Grid controller</div>
        </v-label>

        <v-radio-group
          v-model="formData.settings.gridController"
          row
          persistent-hint
          hint="Who will be able to change the grids during the webinar."
          :rules="[validGridController]"
        >
          <v-radio
            label="Hosts control grid for everyone"
            value="PRESENTER"
          />
          <v-radio
            class="tw-mt-1"
            label="Attendees set their desired grid"
            value="ATTENDEE"
          />
        </v-radio-group>
      </v-col>
      <v-col>
        <v-label>
          <div>Default grid</div>
        </v-label>

        <v-radio-group
          v-model="formData.settings.defaultGrid"
          row
          persistent-hint
          :rules="[validDefaultGrid]"
          hint="The grid that is displayed when a user joins."
        >
          <v-radio
            label="Interactive grid"
            value="INTERACTIVE"
          />
          <v-radio
            label="Presentation grid"
            value="PRESENTATION"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <slot name="videoManagement" />

    <slot name="presenterPermissions" />

    <slot name="attendeePermissions" />

    <v-row
      v-if="showCreatedBy || showLastUpdatedBy"
    >
      <v-col
        v-if="showCreatedBy"
        cols="6"
      >
        <strong>Created by:</strong> {{ createdBy.username }}
      </v-col>
      <v-col
        v-if="showLastUpdatedBy"
        cols="6"
      >
        <strong>Last updated by:</strong> {{ lastUpdatedBy.username }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :disabled="!valid || disabled"
          color="success"
          class="mr-4"
          @click="validate"
        >
          <slot name="confirmButtonText" />
        </v-btn>

        <v-btn
          color="error"
          class="mr-4"
          @click="$emit('form-cancel')"
        >
          <slot name="cancelButtonText" />
        </v-btn>

        <v-btn
          v-if="allowDuplication && !formData.isDefault"
          :disabled="!valid || disabled"
          color="info"
          class="mr-4"
          @click="emitDuplicate"
        >
          <slot name="duplicateButtonText" />
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { isBoolean, isString } from 'lodash-es';
import { isValidURL } from '../../helpers/methods';

const roles = ['PRESENTER', 'ATTENDEE'];
const defaultRoom = {
  name: '',
  pollUrl: '',
  isDefault: false,
  presenterControlUrl: '',
  settings: {
    canLock: false,
    clearChat: true,
    defaultGrid: 'INTERACTIVE',
    gridController: 'PRESENTER',
    showChatModule: true,
    allowChatReplies: true,
    raiseHandAllowed: true,
    showPollingModule: false,
    defaultLaunchStatus: 'IN_PROGRESS',
    showParticipationList: true,
  },
};

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    roomData: {
      type: Object,
      required: false,
      default: () => (defaultRoom),
    },

    allowDuplication: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    valid: true,

    createdBy: {},
    lastUpdatedBy: {},

    formData: {},

    formValidation: {
      name: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 150) || 'Name must be less than 150 characters',
      ],
      raiseHandAllowed: [(v) => isBoolean(v) || 'Please select an option'],
      showChatModule: [(v) => isBoolean(v) || 'Please select an option.'],
      showParticipationList: [(v) => isBoolean(v) || 'Please select an option.'],
      showPollingModule: [(v) => isBoolean(v) || 'Please select an option.'],
      allowChatReplies: [(v) => isBoolean(v) || 'Please select an option'],
    },
  }),

  computed: {
    ...mapState('auth', ['accountType']),

    showCreatedBy() {
      return this.formData && this.formData.createdBy && this.formData.createdBy.username;
    },

    showLastUpdatedBy() {
      return this.formData && this.formData.lastUpdatedBy && this.formData.lastUpdatedBy.username;
    },

    canLock() {
      return this.formData.settings.canLock;
    },
  },

  watch: {
    roomData: {
      deep: true,
      immediate: true,
      handler() {
        // createdBy and lastUpdatedBy are not needed in this.formData
        const { createdBy, lastUpdatedBy, ...room } = this.roomData;
        this.createdBy = createdBy;
        this.lastUpdatedBy = lastUpdatedBy;

        // workaround to keep compatibility with older rooms.
        const settings = {
          canLock: false,
          defaultLaunchStatus: 'IN_PROGRESS',
          ...this.roomData.settings,
        };

        this.formData = {
          ...room,
          settings: { ...settings },
        };
      },
    },

    canLock(lock) {
      if (!lock) this.formData.settings.defaultLaunchStatus = 'IN_PROGRESS';
    },
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('form-submit', {
          ...this.formData,
        });
      }
    },

    emitDuplicate() {
      if (this.$refs.form.validate()) {
        this.$emit('form-duplicate', {
          ...this.formData,
        });
      }
    },

    pollUrlValid() {
      if (!this.formData.settings.showPollingModule) return true;
      return isValidURL(this.formData.pollUrl) || 'Please provide a valid URL for the polling.';
    },

    controlUrlValid() {
      if (!this.formData.settings.showPollingModule) return true;
      return (!this.formData.presenterControlUrl.length
        || isValidURL(this.formData.presenterControlUrl))
          || 'Please provide a valid URL for the Host polling control.';
    },

    validGridController() {
      if (!isString(this.formData.settings.gridController)) return false;
      return roles.includes(this.formData.settings.gridController);
    },

    validDefaultGrid() {
      if (!isString(this.formData.settings.gridController)) return false;
      return roles.includes(this.formData.settings.gridController);
    },
  },
};
</script>
