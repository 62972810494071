<template>
  <div id="attendeePermissionsWizard">
    <v-row>
      <v-col>
        <div class="description">
          <h3>Attendee media permissions</h3>
          <p class="tw-text-xs">
            Please define the desired media access permissions for Attendees.
          </p>
        </div>
      </v-col>
      <v-divider vertical />
      <v-col class="tw-text-xs">
        <p class="mb-1">
          Please note, that the media permissions defined here <b>cannot be changed, extended or
            reduced during an ongoing video conference / neither by Attendees nor Hosts.</b>
        </p>
        <p class="mb-2">
          A Peresenter can revoke and/or grant the defined permissions during the video conference.
        </p>
        <hr>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="formData.mediaPermissions"
          class="tw-mt-0 tw-ml-2"
          row
        >
          <v-radio
            label="No media permissions"
            value="none"
          />
          <v-radio
            label="Audio only"
            value="audio"
          />
          <v-radio
            label="Audio and video"
            value="audio-video"
          />
        </v-radio-group>

        <v-checkbox
          v-model="formData.shareScreen"
          :disabled="!showGrantPermissions"
          class="tw-mt-0 tw-ml-2"
          label="Screen-sharing"
        />

        <v-switch
          v-model="formData.grantPermissionsOnEnlist"
          :disabled="!showGrantPermissions"
          label="Grant media permissions on join"
          color="success"
          class="tw-mt-0 tw-ml-2"
          hide-details
        />

        <v-checkbox
          v-model="formData.setNickname"
          label="Set nickname"
          class="tw-ml-2"
        />

        <v-text-field
          v-if="!formData.setNickname"
          v-model="formData.baseName"
          class="tw-mx-2"
          :counter="30"
          :rules="formValidation.baseName"
          label="Random username base name"
          persistent-hint
          hint="Base to generate a random nickname for Attendees, use letters and numbers only,
            max 50 characters."
          required
        />
      </v-col>
      <v-divider vertical />
      <v-col class="tw-text-sm">
        <div
          v-for="(node, i) in computedPermissions.human"
          :key="i"
        >
          <p v-text="node.desc" />
        </div>

        <p v-if="formData.grantPermissionsOnEnlist">
          <b>Attendees will be able to activate the selected media permissions from the
            moment they join the room.</b>
        </p>
        <p v-if="!showGrantPermissions">
          <b>Attendees won't have permissions granted to them and Hosts cannot grant them
            permissions at any point of the webinar.</b>
        </p>
        <p v-else>
          Hosts <b>must activate the permissions of an individual Attendee</b> for them
          to be able to use their media permissions in this room.
        </p>

        <p v-if="formData.setNickname">
          Attendees will be able to choose their nickname freely.
        </p>
        <p v-else>
          Attendees will need to join the room with a random nickname given to them,
          they can change it during the webinar.
          The given nickname will resemble '{{ formData.baseName }}-00001'
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { find } from 'lodash';
import Permissions from '../../assets/permissions/en/attendee.json';

export default {
  props: {
    defaultData: {
      type: Object,
      required: false,
      default: () => ({
        baseName: 'User',
        setNickname: true,
        shareScreen: false,
        mediaPermissions: 'audio-video',
        grantPermissionsOnEnlist: false,
      }),
    },
  },

  data: () => ({
    items: Permissions,

    formData: {},

    formValidation: {
      baseName: [
        (v) => !!v || 'Base name is required!',
        (v) => (v && v.length <= 150) || 'Base name must be less than 50 characters',
      ],
    },
  }),

  computed: {
    computedPermissions() {
      const permissions = [];
      permissions.push(find(Permissions, ['label', this.formData.mediaPermissions]));

      if (this.formData.shareScreen) permissions.push(find(Permissions, ['label', 'share-screen']));
      if (this.formData.setNickname) permissions.push(find(Permissions, ['label', 'set-nickname']));

      return {
        human: permissions,
        permissions: permissions.map((val) => (val ? val.value : null)).flat(),
      };
    },

    showGrantPermissions() {
      return this.formData.mediaPermissions !== 'none';
    },
  },

  watch: {
    defaultData: {
      deep: true,
      immediate: true,
      handler() {
        this.formData = { ...this.defaultData };
      },
    },

    formData: {
      deep: true,
      handler() {
        if (this.formData.mediaPermissions === 'none') {
          this.formData.shareScreen = false;
          this.formData.grantPermissionsOnEnlist = false;
        }
      },
    },
  },

  methods: {
    getData() {
      const { baseName, grantPermissionsOnEnlist } = this.formData;

      return {
        baseName,
        grantPermissionsOnEnlist,
        permissionsAttendee: this.computedPermissions.permissions,
      };
    },
  },
};
</script>
