<template>
  <div>
    <AttendeePermissions ref="attendeePermissions" />

    <v-row>
      <v-col>
        <v-btn
          color="warning"
          class="mr-4"
          @click="prevStep"
        >
          <slot name="confirmButtonText">
            Previous step
          </slot>
        </v-btn>

        <v-btn
          color="success"
          class="mr-4"
          @click="validate"
        >
          <slot name="confirmButtonText">
            Continue
          </slot>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import AttendeePermissions from '../../forms/AttendeePermissions';

export default {
  components: {
    AttendeePermissions,
  },

  computed: {
    ...mapState('wizard', ['roomId']),
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('rooms', ['updateRoom']),

    ...mapMutations('wizard', ['nextStep', 'prevStep']),

    async validate() {
      try {
        const data = this.$refs.attendeePermissions.getData();

        await this.updateRoom({
          id: this.roomId,
          ...data,
        });

        this.nextStep();
      } catch (err) {
        this.setAlert({ message: 'There was an error updating the room. Please try again later.' });
      }
    },
  },
};
</script>
