<template>
  <div>
    <v-row>
      <v-col>
        <h4 class="text-h6 tw-text-black">
          <slot name="title">
            Select Hosts
          </slot>
        </h4>
        <p class="text-subtitle-1">
          Lookup Hosts by their name here. Can't find them? Create a new one!
        </p>

        <!-- This should be changed for a partial querying mechanism -->
        <v-autocomplete
          v-model="selected"
          :items="computedPresenters"
          :search-input.sync="search"
          item-text="name"
          item-value="value"
          label="Type here to lookup Hosts"
          placeholder="Start typing to search"
          prepend-icon="mdi-account-search"
          return-object
          hide-selected
          chips
          deletable-chips
          multiple
          @change="clearSearch"
        />

        <v-btn
          color="info"
          @click="modal = true"
        >
          Add new Host
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modal"
      max-width="580"
      persistent
    >
      <v-card>
        <template v-if="!addPresenterLoading">
          <v-card-title>
            <span class="headline">Add a new Host</span>
          </v-card-title>

          <v-card-subtitle>
            <slot name="addPresenterSubtitle">
              The Hosts you create here will be added to the newly created room.
            </slot>
          </v-card-subtitle>

          <v-card-text>
            <PresenterForm
              ref="form"
              @form-submit="addPresenter"
              @form-cancel="cancelCreation"
            >
              <template v-slot:cancelButtonText>
                Cancel
              </template>

              <template v-slot:confirmButtonText>
                Create Host
              </template>
            </PresenterForm>
          </v-card-text>
        </template>

        <template v-else>
          <v-card-text>
            <v-card-title>
              <span class="headline">Please wait...</span>
            </v-card-title>

            <v-card-subtitle>
              This can take a few seconds...
            </v-card-subtitle>

            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  find, difference, reject, findIndex,
} from 'lodash';
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import PresenterForm from './PresenterForm';
import scoped from '../../helpers/scoped';

export default {
  components: {
    PresenterForm,
  },

  mixins: [scoped],

  props: {
    roomId: {
      required: false,
      type: String,
      default: '',
    },

    presenterIds: {
      required: false,
      type: Array,
      default: () => ([]),
    },

    presenterPermissions: {
      required: true,
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    search: '',
    valid: true,
    modal: false,
    selected: [],
    addPresenterLoading: false,
  }),

  computed: {
    ...mapState('users', ['users', 'pristine']),

    ...mapState('wizard', ['presenters']),

    ...mapGetters('ScopesModule', ['defaultScope']),

    computedPresenters() {
      if (!this.users.length) return [];
      return this.users.map(this.formatPresenter);
    },
  },

  watch: {
    presenterIds() {
      this.updateSelected();
    },

    selected(newVal, oldVal) {
      const add = difference(newVal, oldVal);
      const rem = difference(oldVal, newVal);

      this.$emit('input', newVal);

      if (rem.length) this.fetchAndDeleteParticipation(rem[0]);
      else if (add.length) this.addParticipation(add[0]);
    },
  },

  async mounted() {
    await this.fetchUsers({ scopeId: this.defaultScope });
    if (this.presenterIds.length) this.updateSelected();
  },

  methods: {
    ...mapActions('users', [
      'fetchUsers',
      'createUser',
    ]),

    ...mapMutations('wizard', [
      'pushPresenter',
      'splicePresenter',
    ]),

    ...mapActions('participations', [
      'fetchParticipations',
      'createParticipation',
      'deleteParticipation',
    ]),

    updateValue(value) {
      this.search = '';
      this.$emit('input', value);
    },

    formatPresenter(presenter, index) {
      return {
        id: index,
        participationId: presenter.participationId || null,
        name: `${presenter.firstName || presenter.nickname} ${presenter.lastName || ''}`,
        value: presenter.userId || presenter.user.userId,
        role: 'Presenter',
        participantToken: presenter.participationToken,
      };
    },

    async addPresenter(presenterData) {
      this.addPresenterLoading = true;

      try {
        const { firstName, lastName, subtitle } = presenterData;
        const newUser = await this.createUser({
          firstName,
          lastName,
          subtitle,
          scopeId: this.defaultScope,
        });
        this.users.push(newUser);
        this.selected.push(this.formatPresenter(newUser));
        this.addParticipation(newUser);
        this.modal = false;
        this.addPresenterLoading = false;
      } catch (err) {
        console.error(err);
      }
    },

    cancelCreation() {
      this.modal = false;
      this.$refs.form.reset();
    },

    updateSelected() {
      this.presenterIds.forEach((id) => {
        const presenter = find(this.users, ['userId', id]);
        const included = this.selected.reduce(
          (acc = false, curr) => acc || curr.value === id, false,
        );
        if (presenter && !included) this.selected.push(presenter);
      });
    },

    getParticipationId(roomId, userId) {
      return this.fetchParticipations({ roomId, userId });
    },

    async fetchAndDeleteParticipation(participation) {
      try {
        const fetched = await this.getParticipationId(
          this.roomId, participation.value,
        );

        await this.deleteParticipation({ participationId: fetched.data[0].participationId });
        this.selected = reject(this.selected, ['value', participation.value]);
        this.$emit('presenters-updated');
        this.splicePresenter(findIndex(this.presenters, ['']));
      } catch (err) {
        console.error(err);
      }
    },

    async addParticipation(participation) {
      try {
        const created = await this.createParticipation({
          roomId: this.roomId,
          userId: participation.userId || participation.value,
          nickname: participation.name || `${participation.firstName} ${participation.lastName}`.trim(),
          role: 'PRESENTER',
          permissions: [...this.presenterPermissions],
        });

        const formatted = this.formatPresenter(
          { ...created.data, userId: participation.userId },
          this.users.length + 1,
        );

        this.$emit('presenters-updated', formatted);
        this.updateSelected();
        this.pushPresenter(formatted);
      } catch (err) {
        console.error(err);
      }
    },

    spliceSelected(id) {
      this.selected.splice(findIndex(this.selected, ['value', id]), 1);
    },

    clearSearch() {
      this.search = '';
    },
  },
};
</script>
