<template>
  <div
    id="roomVideos"
  >
    <v-row>
      <v-col>
        <v-switch
          v-model="formData.videosActive"
          label="Video module active in the room"
          class="tw-ml-2"
          inset
          @change="isValid()"
        />
      </v-col>
    </v-row>

    <v-row
      v-for="(video, i) in formData.videos"
      :key="i"
    >
      <v-col
        cols="12"
        sm="3"
      >
        <v-autocomplete
          v-model="formData.videos[i].dataId"
          :items="computedItems"
          :label="`Selected video ${i+1}`"
          persistent-hint
          clearable
          item-text="name"
          item-value="dataId"
          hint="Video to be displayed."
          @change="(event) => videoChanged(event, i)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-autocomplete
          v-model="formData.videos[i].type"
          :disabled="!formData.videos[i].dataId"
          :items="types"
          persistent-hint
          item-text="name"
          item-value="value"
          label="Video available at"
          hint="Where the video will be shown."
          @change="isValid()"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="formData.videos[i].name"
          :disabled="!formData.videos[i].dataId"
          persistent-hint
          label="Video name"
          hint="
            (Optional) This name will override the filename if given.
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import $http from '../../helpers/api';

const initialVideo = {
  name: null,
  dataId: null,
  type: 'SESSION',
};

export default {
  props: {
    defaultData: {
      type: Object,
      required: false,
      default: () => ({
        videosActive: false,
        videos: [],
      }),
    },
  },

  data: () => ({
    videos: [],
    types: [{
      name: 'INTRO',
      value: 'INTRO',
      disabled: false,
    },
    {
      name: 'SESSION',
      value: 'SESSION',
      disabled: false,
    }],
    formData: {},
  }),

  computed: {
    computedItems() {
      const selectedIds = this.formData.videos.map((v) => v.dataId);
      return this.videos.map((item) => ({
        name: item.name,
        dataId: item.dataId,
        disabled: selectedIds.includes(item.dataId),
      }));
    },
  },

  watch: {
    defaultData: {
      immediate: true,
      handler() {
        const { videosActive, videos } = this.defaultData;
        this.formData = {
          videosActive,
          videos: [...videos, { ...initialVideo }],
        };
      },
    },

    'formData.videos': {
      deep: true,
      handler() {
        const disableIntro = this.formData.videos.map((v) => v.type).filter((v) => v === 'INTRO').length > 0;
        this.types[0].disabled = disableIntro;
      },
    },
  },

  created() {
    $http.get('admin/videos')
      .then((res) => { this.videos = res.data.files; })
      .catch(() => this.setAlert({ message: 'Error fetching room videos, please try again later.' }));
  },

  methods: {
    videoChanged(data, i) {
      if (data) {
        this.formData.videos[i] = {
          ...this.formData.videos[i],
          dataId: data,
        };
        if (!this.formData.videos[i + 1]) this.formData.videos.push({ ...initialVideo });
      } else {
        this.formData.videos.splice(i, 1);
      }
      this.isValid();
    },

    getData() {
      if (typeof this.isValid() === 'boolean') {
        const { videosActive, videos } = this.formData;
        const selectedVideos = videos.filter((video) => video.dataId)
          .map((video) => ({
            type: video.type,
            dataId: video.dataId,
            name: video.name
              || this.videos.find((v) => v.dataId === video.dataId).name,
          }));

        return {
          videosActive,
          videos: selectedVideos,
        };
      }

      return false;
    },

    isValid() {
      const { videosActive, videos } = this.formData;

      if (videosActive && videos.length <= 1) {
        return this.$emit(
          'form-error',
          'Please select a video or set video module to inactive to continue.',
        );
      }

      if (videos.filter((v) => v.type === 'INTRO').length > 1) {
        return this.$emit(
          'form-error',
          'There can only be one introduction video per room.',
        );
      }

      this.$emit('form-error', false);
      return true;
    },
  },
};
</script>
