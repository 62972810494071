const { isEmpty } = require('lodash-es');

export default {
  data: () => ({
    selected: {},
  }),

  computed: {
    dialog() {
      return !isEmpty(this.selected);
    },
  },

  methods: {
    selectItem(item = {}) {
      this.selected = item;
    },
  },
};
