import AddVideos from './AddVideos';
import RoomLinks from './RoomLinks';
import CreateRoom from './CreateRoom';
import AddAttendees from './AddAttendees';
import AddPresenters from './AddPresenters';

export default {
  CreateRoom,
  AddPresenters,
  AddAttendees,
  RoomLinks,
  AddVideos,
};
