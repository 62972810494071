import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

const scoped = {
  computed: {
    ...mapState('ScopesModule', { pristineScopes: 'pristine' }),
    ...mapGetters('ScopesModule', ['multiselectScopes', 'defaultScope']),
  },

  async created() {
    if (this.pristineScopes) await this.listScopes();
  },

  methods: {
    ...mapMutations('ScopesModule', ['setDefaultScope']),
    ...mapActions('ScopesModule', ['listScopes']),

    scopeChanged(scopeId) {
      this.setDefaultScope(scopeId);
    },
  },
};

export default scoped;
