<template>
  <RoomForm
    @form-submit="submitRoom"
    @form-cancel="cancelCreation"
  >
    <template v-slot:cancelButtonText>
      Cancel
    </template>

    <template v-slot:confirmButtonText>
      Continue
    </template>
  </RoomForm>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import scoped from '../../../helpers/scoped';
import RoomForm from '../../forms/RoomForm';

export default {
  components: {
    RoomForm,
  },

  mixins: [scoped],

  computed: {
    ...mapState('wizard', ['roomId']),
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('rooms', ['createRoom', 'updateRoom']),

    ...mapMutations('wizard', [
      'setRoom',
      'nextStep',
      'setRoomId',
      'setEnlistmentToken',
    ]),

    cancelCreation() {
      this.$router.push({ name: 'RoomIndex' });
    },

    async submitRoom(newRoom) {
      const { settings, ...room } = newRoom;
      const roomDTO = {
        ...room,
        scopeId: this.defaultScope,
        settings: {
          ...settings,
          nicknameIsMandatory: true,
          videosActive: false,
        },
      };

      try {
        if (!this.roomId) {
          const createdRoom = await this.createRoom(roomDTO);

          delete createdRoom.createdAt;
          delete createdRoom.updatedAt;
          delete createdRoom.createdBy;

          this.setRoom(createdRoom);
          this.setRoomId(createdRoom.roomId);
          this.setEnlistmentToken(createdRoom.enlistmentToken);
        } else {
          await this.updateRoom({ ...roomDTO, id: this.roomId });
        }
        this.nextStep();
      } catch (error) {
        this.setAlert({
          message: error.response.data.message
            || 'There was an error creating the room. Please try again later.',
        });
      }
    },
  },
};
</script>
