<template>
  <component :is="$route.meta.layout">
    <v-card v-if="false">
      <v-skeleton-loader
        data-cy="skeleton-loader"
        :loading="pristine"
        type="table"
      />
    </v-card>

    <v-card
      v-else
      data-cy="rooms-data-card"
    >
      <v-card-title>
        Created Webinar Rooms
        <v-spacer />
        <v-text-field
          v-model="customParams.name"
          data-cy="rooms-filter"
          append-icon="mdi-magnify"
          name="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        data-cy="rooms-data-table"
        :headers="headers"
        :items="roomData.data"
        :server-items-length="roomData.total"
        :footer-props="{
          itemsPerPageOptions: [15, 30, 50, -1],
        }"
        :items-per-page="pagination.take"
        @pagination="updatePagination"
        @update:items-per-page="updateTake"
        @update:options="updateSort"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-autocomplete
              :value="defaultScope"
              chips
              auto-select-first
              :items="multiselectScopes"
              item-value="scopeId"
              item-text="name"
              label="Select a Scope to use."
              @change="scopeChanged"
            />
            <v-spacer />
            <v-btn
              color="primary"
              small
              class="mb-2 mr-2"
              @click="navigateToCreate"
            >
              Create new Room
            </v-btn>
            <v-btn
              color="info"
              small
              class="mb-2"
              :disabled="!roomData.hasDefault"
              @click="defaultRoomModal = true"
            >
              Create default room
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr
            data-cy="rooms-data-row"
            :class="[
              (item.isDefault && accountType === 'SUPER')
                || !item.isDefault
                ? 'tw-cursor-pointer'
                : 'tw-cursor-not-allowed'
            ]"
            @click="
              (item.isDefault && accountType === 'SUPER')
                || !item.isDefault
                ? navigateToUpdate(item.roomId)
                : null
            "
          >
            <td>
              <v-icon
                v-if="item.isDefault"
                small
              >
                mdi-star-shooting
              </v-icon>
              {{ item.name }}
            </td>
            <td>{{ mapRoomStatus(item.status) }}</td>
            <td>{{ displayUserName(item.createdBy) }}</td>
            <td>{{ displayUserName(item.lastUpdatedBy) }}</td>
            <td>
              <v-tooltip
                v-if="(item.isDefault && accountType === 'SUPER') || !item.isDefault"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-show="['IN_PROGRESS', 'LOCKED'].includes(item.status)"
                    small
                    class="mr-2 hover:tw-text-red-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="attemptCloseRoom(item.roomId)"
                  >
                    mdi-door-closed-lock
                  </v-icon>
                </template>
                <span>Close room</span>
              </v-tooltip>

              <v-tooltip
                v-if="!item.isDefault"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 hover:tw-text-green-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="copyEnlistmentLink(item)"
                  >
                    mdi-link-variant
                  </v-icon>
                </template>
                <span>Copy enlistment link</span>
              </v-tooltip>

              <v-tooltip
                v-if="(item.isDefault && accountType === 'SUPER') || !item.isDefault"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 hover:tw-text-yellow-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="navigateToUpdate(item.roomId)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit room</span>
              </v-tooltip>

              <v-tooltip
                v-if="!item.isDefault"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 hover:tw-text-blue-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="attemptDuplicateRoom(item.roomId)"
                  >
                    mdi-content-duplicate
                  </v-icon>
                </template>
                <span>Duplicate room</span>
              </v-tooltip>

              <v-tooltip
                v-if="(item.isDefault && accountType === 'SUPER') || !item.isDefault"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="hover:tw-text-red-500"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="selectItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete room</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <confirm-dialog :active="dialog">
      <template v-slot:text>
        <p>
          This action will delete the webinar room
          "<span
            class="tw-font-bold"
            v-text="selected.name"
          />".
        </p>
        <p class="tw-text-red-500">
          This action cannot be undone.
        </p>
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="primary"
          @click="selectItem()"
        >
          Cancel
        </v-btn>
      </template>
      <template v-slot:confirm>
        <v-btn
          small
          color="error"
          @click="removeRoom"
        >
          Confirm
        </v-btn>
      </template>
    </confirm-dialog>

    <confirm-dialog :active="defaultRoomModal">
      <template v-slot:text>
        <p>This action creates a new Room using the default configuration in this Scope.</p>

        <v-text-field
          v-model="defaultRoomName"
          label="Name for the created Room."
          :hint="`
            (Optional) if no name is given a name will be assigned to the Room.
            It can be modified later.
          `"
          persistent-hint
        />
      </template>

      <template v-slot:cancel>
        <v-btn
          small
          color="error"
          @click="defaultRoomModal = false"
        >
          Cancel
        </v-btn>
      </template>

      <template v-slot:confirm>
        <v-btn
          small
          color="success"
          @click="attemptCreateDefault"
        >
          Continue
        </v-btn>
      </template>
    </confirm-dialog>
  </component>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { debounce } from 'lodash';
import scoped from '../../helpers/scoped';
import confirms from '../../helpers/confirms';
import pagination from '../../helpers/pagination';
import ConfirmDialog from '../../components/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
  },

  mixins: [scoped, pagination, confirms],

  data: () => ({
    search: '',
    headers: [
      { text: 'Room name', sortable: true, value: 'name' },
      { text: 'Room status', sortable: true, value: 'status' },
      { text: 'Created by', sortable: true, value: 'name' },
      { text: 'Last updated by', sortable: true, value: 'name' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
    roomStatus: [
      { text: 'Locked', value: 'LOCKED' },
      { text: 'Available', value: 'AVAILABLE' },
      { text: 'In progress', value: 'IN_PROGRESS' },
    ],
    defaultRoomName: null,
    defaultRoomModal: false,
  }),

  computed: {
    ...mapGetters(['activeDialog']),
    ...mapState('rooms', [
      'loading', 'pristine', 'roomData',
    ]),
    ...mapState('auth', ['accountType']),

    computedWatch() {
      return [this.computedParams, this.defaultScope];
    },
  },

  watch: {
    computedWatch: {
      deep: true,
      immediate: true,
      // eslint-disable-next-line func-names
      handler: debounce(function () {
        if (this.defaultScope) {
          this.fetchRooms({
            ...this.computedParams,
            scopeId: this.defaultScope,
          });
        }
      }, 250),
    },
  },

  methods: {
    ...mapActions('rooms', [
      'closeRoom',
      'fetchRoom',
      'fetchRooms',
      'deleteRoom',
      'createRoom',
      'updateRoom',
      'duplicateRoom',
      'createRoomFromDefault',
    ]),

    ...mapActions('participations', [
      'fetchParticipations',
      'createParticipation',
    ]),

    ...mapMutations('AlertModule', ['setAlert']),

    navigateToUpdate(roomId) {
      this.$router.push({ name: 'RoomUpdate', params: { roomId } });
    },

    navigateToShow(roomId) {
      this.$router.push({ name: 'RoomShow', params: { roomId } });
    },

    navigateToCreate() {
      this.$router.push({ name: 'RoomCreate' });
    },

    mapRoomStatus(status) {
      return (this.roomStatus.filter((s) => s.value === status)[0] || {}).text;
    },

    displayUserName(user) {
      return (user && user.username) || '-';
    },

    async removeRoom() {
      if (this.selected) {
        await this.deleteRoom(this.selected.roomId);
        this.selectItem();

        this.fetchRooms({
          ...this.computedParams,
          scopeId: this.defaultScope,
        });
      }
    },

    async attemptDuplicateRoom(roomId) {
      try {
        await this.duplicateRoom(roomId);
        this.fetchRooms({
          ...this.computedParams,
          scopeId: this.defaultScope,
        });
      } catch (error) {
        this.setAlert({ message: 'Error duplicating room, please try again later.' });
      }
    },

    async attemptCloseRoom(roomId) {
      try {
        await this.closeRoom(roomId);

        this.fetchRooms({
          ...this.computedParams,
          scopeId: this.defaultScope,
        });
      } catch (error) {
        this.setAlert({ message: 'Error closing room, please try again later.' });
      }
    },

    copyEnlistmentLink(room) {
      this.setAlert({
        message: `General enlistment URL of room "${room.name}" copied to clipboard!`,
        type: 'success',
        icon: 'mdi-link-variant',
      });
      navigator.clipboard.writeText(`${process.env.VUE_APP_WEBINAR_BASE_URL}?token=${room.enlistmentToken}`);
    },

    async attemptCreateDefault() {
      this.defaultRoomModal = false;

      await this.createRoomFromDefault({
        name: this.defaultRoomName,
        scopeId: this.defaultScope,
      });

      this.fetchRooms({
        ...this.computedParams,
        scopeId: this.defaultScope,
      });
    },
  },
};
</script>
