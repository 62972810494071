<template>
  <v-card>
    <v-card-title primary-title>
      Hosts in this room
      <v-spacer />
      <v-btn
        class="tw-normal-case"
        color="primary"
        small
        dark
        @click.stop="copyEnlistmentLink"
      >
        <v-icon left>
          mdi-link
        </v-icon>
        Copy attendee join link
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-switch
        v-model="debug"
        inset
        :label="`${debug ? 'Using debug mode' : 'Debug mode disabled'}`"
      />
    </v-card-text>

    <v-card-text>
      <slot name="finder" />
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="computedPresenters"
    >
      <template v-slot:[`header.actions`]="{ header }">
        <span class="tw-right">{{ header.text }}</span>
      </template>
      <template v-slot:item="{ item }">
        <tr data-cy="rooms-data-row">
          <td v-text="item.nickname || item.name" />
          <td>
            {{ item.role | capitalize }}
          </td>
          <td class="tw-flex tw-flex-row-reverse tw-justify-start tw-items-center">
            <v-btn
              class="tw-normal-case ml-2"
              color="error"
              dark
              small
              @click.stop="deletePresenter(item.participationId)"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Remove Host
            </v-btn>

            <v-btn
              class="tw-normal-case"
              color="primary"
              dark
              small
              @click.stop="copyToClipboard(item.participantToken || item.participationToken)"
            >
              <v-icon left>
                mdi-link
              </v-icon>
              Copy Host link
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import confirms from '../helpers/confirms';

export default {
  filters: {
    capitalize: (value) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
  },

  mixins: [confirms],

  props: {
    presenters: {
      type: Array,
      required: true,
      default: () => ([]),
    },

    enlistmentToken: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    debug: (process.env.VUE_APP_USE_DEBUG === 'true'),
    headers: [
      { text: 'Name', sortable: true, value: 'nickname' },
      { text: 'Role', sortable: true, value: 'role' },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
        align: 'end',
      },
    ],
  }),

  computed: {
    computedPresenters() {
      return this.presenters.map((p) => this.formatPresenter(p));
    },

    computedEnlistmentToken() {
      return this.enlistmentToken;
    },
  },

  methods: {
    ...mapActions('participations', ['deleteParticipation']),

    formatPresenter(presenter) {
      return {
        ...presenter,
        nickname: presenter.user
          ? `${presenter.user.firstName} ${presenter.user.lastName}` : presenter.nickname,
      };
    },

    copyToClipboard(joinToken) {
      this.alert = { message: 'Host join URL copied to clipboard!' };
      navigator.clipboard.writeText(`${process.env.VUE_APP_WEBINAR_BASE_URL}?token=${joinToken}${this.debug ? '&debug=true' : ''}`);
      setTimeout(() => {
        this.alert = false;
      }, 2500);
    },

    copyEnlistmentLink() {
      this.alert = { message: 'General enlistment URL copied to clipboard!' };
      navigator.clipboard.writeText(`${process.env.VUE_APP_WEBINAR_BASE_URL}?token=${this.computedEnlistmentToken}${this.debug ? '&debug=true' : ''}`);
      setTimeout(() => {
        this.alert = false;
      }, 2500);
    },

    navigateToUpdate(presenterId) {
      this.$router.push({ name: 'PresenterUpdate', params: { presenterId } });
    },

    async deletePresenter(participationId) {
      await this.deleteParticipation({
        usesLoading: true,
        participationId,
      });

      this.$emit('presenters-updated', null);
    },
  },
};
</script>
