var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.$route.meta.layout,{tag:"component"},[(false)?_c('v-card',[_c('v-skeleton-loader',{attrs:{"data-cy":"skeleton-loader","loading":_vm.pristine,"type":"table"}})],1):_c('v-card',{attrs:{"data-cy":"rooms-data-card"}},[_c('v-card-title',[_vm._v(" Created Webinar Rooms "),_c('v-spacer'),_c('v-text-field',{attrs:{"data-cy":"rooms-filter","append-icon":"mdi-magnify","name":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.customParams.name),callback:function ($$v) {_vm.$set(_vm.customParams, "name", $$v)},expression:"customParams.name"}})],1),_c('v-data-table',{attrs:{"data-cy":"rooms-data-table","headers":_vm.headers,"items":_vm.roomData.data,"server-items-length":_vm.roomData.total,"footer-props":{
        itemsPerPageOptions: [15, 30, 50, -1],
      },"items-per-page":_vm.pagination.take},on:{"pagination":_vm.updatePagination,"update:items-per-page":_vm.updateTake,"update:options":_vm.updateSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-autocomplete',{attrs:{"value":_vm.defaultScope,"chips":"","auto-select-first":"","items":_vm.multiselectScopes,"item-value":"scopeId","item-text":"name","label":"Select a Scope to use."},on:{"change":_vm.scopeChanged}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","small":""},on:{"click":_vm.navigateToCreate}},[_vm._v(" Create new Room ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"info","small":"","disabled":!_vm.roomData.hasDefault},on:{"click":function($event){_vm.defaultRoomModal = true}}},[_vm._v(" Create default room ")])],1)]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{class:[
            (item.isDefault && _vm.accountType === 'SUPER')
              || !item.isDefault
              ? 'tw-cursor-pointer'
              : 'tw-cursor-not-allowed'
          ],attrs:{"data-cy":"rooms-data-row"},on:{"click":function($event){(item.isDefault && _vm.accountType === 'SUPER')
              || !item.isDefault
              ? _vm.navigateToUpdate(item.roomId)
              : null}}},[_c('td',[(item.isDefault)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-star-shooting ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('td',[_vm._v(_vm._s(_vm.mapRoomStatus(item.status)))]),_c('td',[_vm._v(_vm._s(_vm.displayUserName(item.createdBy)))]),_c('td',[_vm._v(_vm._s(_vm.displayUserName(item.lastUpdatedBy)))]),_c('td',[((item.isDefault && _vm.accountType === 'SUPER') || !item.isDefault)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(['IN_PROGRESS', 'LOCKED'].includes(item.status)),expression:"['IN_PROGRESS', 'LOCKED'].includes(item.status)"}],staticClass:"mr-2 hover:tw-text-red-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.attemptCloseRoom(item.roomId)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-door-closed-lock ")])]}}],null,true)},[_c('span',[_vm._v("Close room")])]):_vm._e(),(!item.isDefault)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 hover:tw-text-green-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyEnlistmentLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link-variant ")])]}}],null,true)},[_c('span',[_vm._v("Copy enlistment link")])]):_vm._e(),((item.isDefault && _vm.accountType === 'SUPER') || !item.isDefault)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 hover:tw-text-yellow-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToUpdate(item.roomId)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit room")])]):_vm._e(),(!item.isDefault)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 hover:tw-text-blue-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.attemptDuplicateRoom(item.roomId)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-duplicate ")])]}}],null,true)},[_c('span',[_vm._v("Duplicate room")])]):_vm._e(),((item.isDefault && _vm.accountType === 'SUPER') || !item.isDefault)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"hover:tw-text-red-500",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete room")])]):_vm._e()],1)])]}}])})],1),_c('confirm-dialog',{attrs:{"active":_vm.dialog},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v(" This action will delete the webinar room \""),_c('span',{staticClass:"tw-font-bold",domProps:{"textContent":_vm._s(_vm.selected.name)}}),_vm._v("\". ")]),_c('p',{staticClass:"tw-text-red-500"},[_vm._v(" This action cannot be undone. ")])]},proxy:true},{key:"cancel",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.selectItem()}}},[_vm._v(" Cancel ")])]},proxy:true},{key:"confirm",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":_vm.removeRoom}},[_vm._v(" Confirm ")])]},proxy:true}])}),_c('confirm-dialog',{attrs:{"active":_vm.defaultRoomModal},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v("This action creates a new Room using the default configuration in this Scope.")]),_c('v-text-field',{attrs:{"label":"Name for the created Room.","hint":"\n          (Optional) if no name is given a name will be assigned to the Room.\n          It can be modified later.\n        ","persistent-hint":""},model:{value:(_vm.defaultRoomName),callback:function ($$v) {_vm.defaultRoomName=$$v},expression:"defaultRoomName"}})]},proxy:true},{key:"cancel",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){_vm.defaultRoomModal = false}}},[_vm._v(" Cancel ")])]},proxy:true},{key:"confirm",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.attemptCreateDefault}},[_vm._v(" Continue ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }